body {
  background-image: url("https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/fondo_app.png");
  background-position: center center;
  background-repeat:  no-repeat;
  background-attachment: fixed;
  background-size:  cover;
  font-family: "Open Sans";
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-left: 25%;
  padding-right: 25%;
}

/* celular */
@media only screen and (max-width: 768px){
  body {
    background-image: url("https://storage.googleapis.com/fundes-gestion-talento/imagenes/app/fundes_star/fondo_app.png");
    background-position: center center;
    background-repeat:  no-repeat;
    background-attachment: fixed;
    background-size:  cover; 
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    font-size: 95%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-left: 0%;
    padding-right: 0%;
    
  }

}

/* html, body, .Login-component {
  background-position: center center;
  background-repeat:  no-repeat;
  background-attachment: fixed;
  background-size:  cover;
  margin: 0;
} */

code {
  font-family: "Open Sans";
    
}

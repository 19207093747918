/* .App {
    font-family: sans-serif;
    text-align: center;
  }
  
   .reactions {
    margin-top: 20px;
  } 

  */

  /* .reactions {
    /* margin-top: 20px;
    padding-bottom: 18%;
  }  */

  .reactions .reactions-counter {
     display: flex;
    /* flex-wrap: wrap; */
  }
  .reactions .reactions-position {
    padding-bottom: 32%;
   /* flex-wrap: wrap; */
 }
 
  .reactions .emoji-mart {
    z-index: 999;
  }
  .reactions .emoji-mart-anchors {
    display: none;
  }
  .reactions .emoji-mart-search {
    display: none;
  }
  .reactions .emoji-mart-bar {
    display: none;
  }
  .reactions .emoji-mart-scroll {
    overflow: auto;
    height: auto;
  }
  .reactions .emoji-mart-category-label {
    display: none;
  }
   